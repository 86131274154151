<template>
	<div>
		<div class="register-info p-3">
			<p class="font-weight-bold">
				Učitelia, vytvorte si online triedy a zapojte sa do SÚŤAŽE.
				<a href="https://drive.google.com/file/d/1Ka2J30IWcFyR6FI4tWtX2yIYDpzUVZMk/view?usp=sharing" target="_blank" class="link">
					Viac info
				</a>
			</p>
		</div>
		<div class="a-profile-subpage-title -user-manual">
			<div>
				<h1 class="w-color-purple-user-manual">Pomocník</h1>
				<p class="w-color-black">Návody, tipy a triky na to, ako používať portál</p>
			</div>
			<div class="z-icon-wrapper -large">
				<svg-user-manual-icon class="w-color-purple-user-manual -ribbon" />
			</div>
		</div>
		<div class="pt-3 pl-2 pr-2">
			<h2 class="mt-3 w-color-black">1. Ako vytvoriť triedu</h2>
			<div
				class="embed-container"
			>
				<iframe
					src="https://www.youtube.com/embed/Phj5A2FyM0U" frameborder="0" allowfullscreen
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				></iframe>
			</div>

			<h2 class="mt-3 w-color-black">2. Ako pridať študentov do triedy</h2>
			<div
				class="embed-container"
			>
				<iframe
					src="https://www.youtube.com/embed/wuts3YoiuwM" frameborder="0" allowfullscreen
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				></iframe>
			</div>

			<h2 class="mt-3 w-color-black">3. Ako zadať úlohu triede</h2>
			<div
				class="embed-container"
			>
				<iframe
					src="https://www.youtube.com/embed/7NlyvpIXidc" frameborder="0" allowfullscreen
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				></iframe>
			</div>

			<h2 class="mt-3 w-color-black">4. Ako získať diplom</h2>
			<div
				class="embed-container"
			>
				<iframe
					src="https://www.youtube.com/embed/AxLtJCN9Z8c" frameborder="0" allowfullscreen
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				></iframe>
			</div>

			<h2 class="mt-3 w-color-black">5. Ako pracovať s metodikami</h2>
			<div
				class="embed-container"
			>
				<iframe
					src="https://www.youtube.com/embed/Da3cJwxXtaA" frameborder="0" allowfullscreen
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				></iframe>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	layout: 'profile',

	components: {
		'svg-user-manual-icon':		() => import('@/plugins/appzmudri/_theme/icon/user-manual.svg?inline'),
	},

	computed: {
		...mapGetters('wAuth', [
			'user'
		])
	}
}
</script>

<style lang="scss" scoped>
h2 {
	font-size: 20px;
}

.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	overflow: hidden;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	width: 100%;
	height: 100%;
}

.register-info {
	font-family: 'Poppins';
	max-width: 467px;
	background: #FFFFFF;
	border: 1px solid #FF0000;
	border-radius: 4px;
	margin: 20px auto;
	display: flex;
	align-items: center;

	p {
		color: #FF0000;
		white-space: normal;
		margin: 0;

		a.link {
			font-weight: 700;
			color: inherit;
			text-decoration: underline;
		}
	}

	@media only screen and (max-width: 480px){
		max-width: 20em;
	}
}
</style>